import React from "react"
import { Link } from "gatsby"
import Pink from "../../static/assets/equili-full-pink.png"


const Logo = () => (
  <div className="site-logo">
     <img src={Pink} alt="Logo"/>
  </div>
)
export default Logo